import { dew as _utilDew } from "../util";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var util = _utilDew();

  function QueryParamSerializer() {}

  QueryParamSerializer.prototype.serialize = function (params, shape, fn) {
    serializeStructure("", params, shape, fn);
  };

  function ucfirst(shape) {
    if (shape.isQueryName || shape.api.protocol !== "ec2") {
      return shape.name;
    } else {
      return shape.name[0].toUpperCase() + shape.name.substr(1);
    }
  }

  function serializeStructure(prefix, struct, rules, fn) {
    util.each(rules.members, function (name, member) {
      var value = struct[name];
      if (value === null || value === undefined) return;
      var memberName = ucfirst(member);
      memberName = prefix ? prefix + "." + memberName : memberName;
      serializeMember(memberName, value, member, fn);
    });
  }

  function serializeMap(name, map, rules, fn) {
    var i = 1;
    util.each(map, function (key, value) {
      var prefix = rules.flattened ? "." : ".entry.";
      var position = prefix + i++ + ".";
      var keyName = position + (rules.key.name || "key");
      var valueName = position + (rules.value.name || "value");
      serializeMember(name + keyName, key, rules.key, fn);
      serializeMember(name + valueName, value, rules.value, fn);
    });
  }

  function serializeList(name, list, rules, fn) {
    var memberRules = rules.member || {};

    if (list.length === 0) {
      fn.call(this || _global, name, null);
      return;
    }

    util.arrayEach(list, function (v, n) {
      var suffix = "." + (n + 1);

      if (rules.api.protocol === "ec2") {
        // Do nothing for EC2
        suffix = suffix + ""; // make linter happy
      } else if (rules.flattened) {
        if (memberRules.name) {
          var parts = name.split(".");
          parts.pop();
          parts.push(ucfirst(memberRules));
          name = parts.join(".");
        }
      } else {
        suffix = "." + (memberRules.name ? memberRules.name : "member") + suffix;
      }

      serializeMember(name + suffix, v, memberRules, fn);
    });
  }

  function serializeMember(name, value, rules, fn) {
    if (value === null || value === undefined) return;

    if (rules.type === "structure") {
      serializeStructure(name, value, rules, fn);
    } else if (rules.type === "list") {
      serializeList(name, value, rules, fn);
    } else if (rules.type === "map") {
      serializeMap(name, value, rules, fn);
    } else {
      fn(name, rules.toWireFormat(value).toString());
    }
  }
  /**
   * @api private
   */


  exports = QueryParamSerializer;
  return exports;
}