import * as _core from "../core";
import { dew as _vDew } from "./v3";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var AWS = _core.__dew ? _core.__dew() : _core.default;
  var inherit = AWS.util.inherit;

  _vDew();
  /**
   * @api private
   */


  AWS.Signers.V3Https = inherit(AWS.Signers.V3, {
    authorization: function authorization(credentials) {
      return "AWS3-HTTPS " + "AWSAccessKeyId=" + credentials.accessKeyId + "," + "Algorithm=HmacSHA256," + "Signature=" + this.signature(credentials);
    },
    stringToSign: function stringToSign() {
      return (this || _global).request.headers["X-Amz-Date"];
    }
  });
  /**
   * @api private
   */

  exports = AWS.Signers.V3Https;
  return exports;
}