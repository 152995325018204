var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  function apiLoader(svc, version) {
    if (!apiLoader.services.hasOwnProperty(svc)) {
      throw new Error("InvalidService: Failed to load api for " + svc);
    }

    return apiLoader.services[svc][version];
  }
  /**
   * @api private
   *
   * This member of AWS.apiLoader is private, but changing it will necessitate a
   * change to ../scripts/services-table-generator.ts
   */


  apiLoader.services = {};
  /**
   * @api private
   */

  exports = apiLoader;
  return exports;
}