import { dew as _utilDew } from "../util";
import { dew as _restDew } from "./rest";
import { dew as _jsonDew } from "./json";
import { dew as _builderDew } from "../json/builder";
import { dew as _parserDew } from "../json/parser";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var util = _utilDew();

  var Rest = _restDew();

  var Json = _jsonDew();

  var JsonBuilder = _builderDew();

  var JsonParser = _parserDew();

  function populateBody(req) {
    var builder = new JsonBuilder();
    var input = req.service.api.operations[req.operation].input;

    if (input.payload) {
      var params = {};
      var payloadShape = input.members[input.payload];
      params = req.params[input.payload];
      if (params === undefined) return;

      if (payloadShape.type === "structure") {
        req.httpRequest.body = builder.build(params, payloadShape);
        applyContentTypeHeader(req);
      } else {
        // non-JSON payload
        req.httpRequest.body = params;

        if (payloadShape.type === "binary" || payloadShape.isStreaming) {
          applyContentTypeHeader(req, true);
        }
      }
    } else {
      var body = builder.build(req.params, input);

      if (body !== "{}" || req.httpRequest.method !== "GET") {
        //don't send empty body for GET method
        req.httpRequest.body = body;
      }

      applyContentTypeHeader(req);
    }
  }

  function applyContentTypeHeader(req, isBinary) {
    var operation = req.service.api.operations[req.operation];
    var input = operation.input;

    if (!req.httpRequest.headers["Content-Type"]) {
      var type = isBinary ? "binary/octet-stream" : "application/json";
      req.httpRequest.headers["Content-Type"] = type;
    }
  }

  function buildRequest(req) {
    Rest.buildRequest(req); // never send body payload on HEAD/DELETE

    if (["HEAD", "DELETE"].indexOf(req.httpRequest.method) < 0) {
      populateBody(req);
    }
  }

  function extractError(resp) {
    Json.extractError(resp);
  }

  function extractData(resp) {
    Rest.extractData(resp);
    var req = resp.request;
    var operation = req.service.api.operations[req.operation];
    var rules = req.service.api.operations[req.operation].output || {};
    var parser;
    var hasEventOutput = operation.hasEventOutput;

    if (rules.payload) {
      var payloadMember = rules.members[rules.payload];
      var body = resp.httpResponse.body;

      if (payloadMember.isEventStream) {
        parser = new JsonParser();
        resp.data[payload] = util.createEventStream(AWS.HttpClient.streamsApiVersion === 2 ? resp.httpResponse.stream : body, parser, payloadMember);
      } else if (payloadMember.type === "structure" || payloadMember.type === "list") {
        var parser = new JsonParser();
        resp.data[rules.payload] = parser.parse(body, payloadMember);
      } else if (payloadMember.type === "binary" || payloadMember.isStreaming) {
        resp.data[rules.payload] = body;
      } else {
        resp.data[rules.payload] = payloadMember.toType(body);
      }
    } else {
      var data = resp.data;
      Json.extractData(resp);
      resp.data = util.merge(data, resp.data);
    }
  }
  /**
   * @api private
   */


  exports = {
    buildRequest: buildRequest,
    extractError: extractError,
    extractData: extractData
  };
  return exports;
}