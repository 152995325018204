var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  /**
   * Escapes characters that can not be in an XML element.
   */
  function escapeElement(value) {
    return value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
  }
  /**
   * @api private
   */


  exports = {
    escapeElement: escapeElement
  };
  return exports;
}