import * as _core from "../core";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var AWS = _core.__dew ? _core.__dew() : _core.default;
  var inherit = AWS.util.inherit;
  /**
   * @api private
   */

  AWS.Signers.V3 = inherit(AWS.Signers.RequestSigner, {
    addAuthorization: function addAuthorization(credentials, date) {
      var datetime = AWS.util.date.rfc822(date);
      (this || _global).request.headers["X-Amz-Date"] = datetime;

      if (credentials.sessionToken) {
        (this || _global).request.headers["x-amz-security-token"] = credentials.sessionToken;
      }

      (this || _global).request.headers["X-Amzn-Authorization"] = this.authorization(credentials, datetime);
    },
    authorization: function authorization(credentials) {
      return "AWS3 " + "AWSAccessKeyId=" + credentials.accessKeyId + "," + "Algorithm=HmacSHA256," + "SignedHeaders=" + this.signedHeaders() + "," + "Signature=" + this.signature(credentials);
    },
    signedHeaders: function signedHeaders() {
      var headers = [];
      AWS.util.arrayEach(this.headersToSign(), function iterator(h) {
        headers.push(h.toLowerCase());
      });
      return headers.sort().join(";");
    },
    canonicalHeaders: function canonicalHeaders() {
      var headers = (this || _global).request.headers;
      var parts = [];
      AWS.util.arrayEach(this.headersToSign(), function iterator(h) {
        parts.push(h.toLowerCase().trim() + ":" + String(headers[h]).trim());
      });
      return parts.sort().join("\n") + "\n";
    },
    headersToSign: function headersToSign() {
      var headers = [];
      AWS.util.each((this || _global).request.headers, function iterator(k) {
        if (k === "Host" || k === "Content-Encoding" || k.match(/^X-Amz/i)) {
          headers.push(k);
        }
      });
      return headers;
    },
    signature: function signature(credentials) {
      return AWS.util.crypto.hmac(credentials.secretAccessKey, this.stringToSign(), "base64");
    },
    stringToSign: function stringToSign() {
      var parts = [];
      parts.push((this || _global).request.method);
      parts.push("/");
      parts.push("");
      parts.push(this.canonicalHeaders());
      parts.push((this || _global).request.body);
      return AWS.util.crypto.sha256(parts.join("\n"));
    }
  });
  /**
   * @api private
   */

  exports = AWS.Signers.V3;
  return exports;
}