import { dew as _escapeAttributeDew } from "./escape-attribute";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var escapeAttribute = _escapeAttributeDew().escapeAttribute;
  /**
   * Represents an XML node.
   * @api private
   */


  function XmlNode(name, children) {
    if (children === void 0) {
      children = [];
    }

    (this || _global).name = name;
    (this || _global).children = children;
    (this || _global).attributes = {};
  }

  XmlNode.prototype.addAttribute = function (name, value) {
    (this || _global).attributes[name] = value;
    return this || _global;
  };

  XmlNode.prototype.addChildNode = function (child) {
    (this || _global).children.push(child);

    return this || _global;
  };

  XmlNode.prototype.removeAttribute = function (name) {
    delete (this || _global).attributes[name];
    return this || _global;
  };

  XmlNode.prototype.toString = function () {
    var hasChildren = Boolean((this || _global).children.length);
    var xmlText = "<" + (this || _global).name; // add attributes

    var attributes = (this || _global).attributes;

    for (var i = 0, attributeNames = Object.keys(attributes); i < attributeNames.length; i++) {
      var attributeName = attributeNames[i];
      var attribute = attributes[attributeName];

      if (typeof attribute !== "undefined" && attribute !== null) {
        xmlText += " " + attributeName + "=\"" + escapeAttribute("" + attribute) + "\"";
      }
    }

    return xmlText += !hasChildren ? "/>" : ">" + (this || _global).children.map(function (c) {
      return c.toString();
    }).join("") + "</" + (this || _global).name + ">";
  };
  /**
   * @api private
   */


  exports = {
    XmlNode: XmlNode
  };
  return exports;
}