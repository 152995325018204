import { dew as _utilDew } from "../util";
import { dew as _builderDew } from "../json/builder";
import { dew as _parserDew } from "../json/parser";
import { dew as _helpersDew } from "./helpers";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var util = _utilDew();

  var JsonBuilder = _builderDew();

  var JsonParser = _parserDew();

  var populateHostPrefix = _helpersDew().populateHostPrefix;

  function buildRequest(req) {
    var httpRequest = req.httpRequest;
    var api = req.service.api;
    var target = api.targetPrefix + "." + api.operations[req.operation].name;
    var version = api.jsonVersion || "1.0";
    var input = api.operations[req.operation].input;
    var builder = new JsonBuilder();
    if (version === 1) version = "1.0";
    httpRequest.body = builder.build(req.params || {}, input);
    httpRequest.headers["Content-Type"] = "application/x-amz-json-" + version;
    httpRequest.headers["X-Amz-Target"] = target;
    populateHostPrefix(req);
  }

  function extractError(resp) {
    var error = {};
    var httpResponse = resp.httpResponse;
    error.code = httpResponse.headers["x-amzn-errortype"] || "UnknownError";

    if (typeof error.code === "string") {
      error.code = error.code.split(":")[0];
    }

    if (httpResponse.body.length > 0) {
      try {
        var e = JSON.parse(httpResponse.body.toString());
        var code = e.__type || e.code || e.Code;

        if (code) {
          error.code = code.split("#").pop();
        }

        if (error.code === "RequestEntityTooLarge") {
          error.message = "Request body must be less than 1 MB";
        } else {
          error.message = e.message || e.Message || null;
        }
      } catch (e) {
        error.statusCode = httpResponse.statusCode;
        error.message = httpResponse.statusMessage;
      }
    } else {
      error.statusCode = httpResponse.statusCode;
      error.message = httpResponse.statusCode.toString();
    }

    resp.error = util.error(new Error(), error);
  }

  function extractData(resp) {
    var body = resp.httpResponse.body.toString() || "{}";

    if (resp.request.service.config.convertResponseTypes === false) {
      resp.data = JSON.parse(body);
    } else {
      var operation = resp.request.service.api.operations[resp.request.operation];
      var shape = operation.output || {};
      var parser = new JsonParser();
      resp.data = parser.parse(body, shape);
    }
  }
  /**
   * @api private
   */


  exports = {
    buildRequest: buildRequest,
    extractError: extractError,
    extractData: extractData
  };
  return exports;
}