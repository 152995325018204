import { dew as _utilDew } from "./util";
import { dew as _jsonDew } from "./protocol/json";
import { dew as _queryDew } from "./protocol/query";
import { dew as _restDew } from "./protocol/rest";
import { dew as _rest_jsonDew } from "./protocol/rest_json";
import { dew as _rest_xmlDew } from "./protocol/rest_xml";
import { dew as _builderDew } from "./xml/builder";
import { dew as _builder2Dew } from "./json/builder";
import { dew as _parserDew } from "./json/parser";
import { dew as _apiDew } from "./model/api";
import { dew as _operationDew } from "./model/operation";
import { dew as _shapeDew } from "./model/shape";
import { dew as _paginatorDew } from "./model/paginator";
import { dew as _resource_waiterDew } from "./model/resource_waiter";
import { dew as _api_loaderDew } from "./api_loader";
import { dew as _endpointCacheDew } from "../vendor/endpoint-cache";
import { dew as _sequential_executorDew } from "./sequential_executor";
import { dew as _serviceDew } from "./service";
import { dew as _configDew } from "./config";
import { dew as _httpDew } from "./http";
import { dew as _event_listenersDew } from "./event_listeners";
import { dew as _requestDew } from "./request";
import { dew as _responseDew } from "./response";
import { dew as _resource_waiter2Dew } from "./resource_waiter";
import { dew as _request_signerDew } from "./signers/request_signer";
import { dew as _param_validatorDew } from "./param_validator";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  /**
   * The main AWS namespace
   */
  var AWS = {
    util: _utilDew()
  };
  /**
   * @api private
   * @!macro [new] nobrowser
   *   @note This feature is not supported in the browser environment of the SDK.
   */

  var _hidden = {};

  _hidden.toString(); // hack to parse macro

  /**
   * @api private
   */


  exports = AWS;
  AWS.util.update(AWS, {
    /**
     * @constant
     */
    VERSION: "2.756.0",

    /**
     * @api private
     */
    Signers: {},

    /**
     * @api private
     */
    Protocol: {
      Json: _jsonDew(),
      Query: _queryDew(),
      Rest: _restDew(),
      RestJson: _rest_jsonDew(),
      RestXml: _rest_xmlDew()
    },

    /**
     * @api private
     */
    XML: {
      Builder: _builderDew(),
      Parser: null // conditionally set based on environment

    },

    /**
     * @api private
     */
    JSON: {
      Builder: _builder2Dew(),
      Parser: _parserDew()
    },

    /**
     * @api private
     */
    Model: {
      Api: _apiDew(),
      Operation: _operationDew(),
      Shape: _shapeDew(),
      Paginator: _paginatorDew(),
      ResourceWaiter: _resource_waiterDew()
    },

    /**
     * @api private
     */
    apiLoader: _api_loaderDew(),

    /**
     * @api private
     */
    EndpointCache: _endpointCacheDew().EndpointCache
  });

  _sequential_executorDew();

  _serviceDew();

  _configDew();

  _httpDew();

  _event_listenersDew();

  _requestDew();

  _responseDew();

  _resource_waiter2Dew();

  _request_signerDew();

  _param_validatorDew();
  /**
   * @readonly
   * @return [AWS.SequentialExecutor] a collection of global event listeners that
   *   are attached to every sent request.
   * @see AWS.Request AWS.Request for a list of events to listen for
   * @example Logging the time taken to send a request
   *   AWS.events.on('send', function startSend(resp) {
   *     resp.startTime = new Date().getTime();
   *   }).on('complete', function calculateTime(resp) {
   *     var time = (new Date().getTime() - resp.startTime) / 1000;
   *     console.log('Request took ' + time + ' seconds');
   *   });
   *
   *   new AWS.S3().listBuckets(); // prints 'Request took 0.285 seconds'
   */


  AWS.events = new AWS.SequentialExecutor(); //create endpoint cache lazily

  AWS.util.memoizedProperty(AWS, "endpointCache", function () {
    return new AWS.EndpointCache(AWS.config.endpointCacheSize);
  }, true);
  return exports;
}