import { dew as _utilDew } from "../util";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var util = _utilDew();

  function JsonParser() {}

  JsonParser.prototype.parse = function (value, shape) {
    return translate(JSON.parse(value), shape);
  };

  function translate(value, shape) {
    if (!shape || value === undefined) return undefined;

    switch (shape.type) {
      case "structure":
        return translateStructure(value, shape);

      case "map":
        return translateMap(value, shape);

      case "list":
        return translateList(value, shape);

      default:
        return translateScalar(value, shape);
    }
  }

  function translateStructure(structure, shape) {
    if (structure == null) return undefined;
    var struct = {};
    var shapeMembers = shape.members;
    util.each(shapeMembers, function (name, memberShape) {
      var locationName = memberShape.isLocationName ? memberShape.name : name;

      if (Object.prototype.hasOwnProperty.call(structure, locationName)) {
        var value = structure[locationName];
        var result = translate(value, memberShape);
        if (result !== undefined) struct[name] = result;
      }
    });
    return struct;
  }

  function translateList(list, shape) {
    if (list == null) return undefined;
    var out = [];
    util.arrayEach(list, function (value) {
      var result = translate(value, shape.member);
      if (result === undefined) out.push(null);else out.push(result);
    });
    return out;
  }

  function translateMap(map, shape) {
    if (map == null) return undefined;
    var out = {};
    util.each(map, function (key, value) {
      var result = translate(value, shape.value);
      if (result === undefined) out[key] = null;else out[key] = result;
    });
    return out;
  }

  function translateScalar(value, shape) {
    return shape.toType(value);
  }
  /**
   * @api private
   */


  exports = JsonParser;
  return exports;
}