var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  function AcceptorStateMachine(states, state) {
    (this || _global).currentState = state || null;
    (this || _global).states = states || {};
  }

  AcceptorStateMachine.prototype.runTo = function runTo(finalState, done, bindObject, inputError) {
    if (typeof finalState === "function") {
      inputError = bindObject;
      bindObject = done;
      done = finalState;
      finalState = null;
    }

    var self = this || _global;
    var state = self.states[self.currentState];
    state.fn.call(bindObject || self, inputError, function (err) {
      if (err) {
        if (state.fail) self.currentState = state.fail;else return done ? done.call(bindObject, err) : null;
      } else {
        if (state.accept) self.currentState = state.accept;else return done ? done.call(bindObject) : null;
      }

      if (self.currentState === finalState) {
        return done ? done.call(bindObject, err) : null;
      }

      self.runTo(finalState, done, bindObject, err);
    });
  };

  AcceptorStateMachine.prototype.addState = function addState(name, acceptState, failState, fn) {
    if (typeof acceptState === "function") {
      fn = acceptState;
      acceptState = null;
      failState = null;
    } else if (typeof failState === "function") {
      fn = failState;
      failState = null;
    }

    if (!(this || _global).currentState) (this || _global).currentState = name;
    (this || _global).states[name] = {
      accept: acceptState,
      fail: failState,
      fn: fn
    };
    return this || _global;
  };
  /**
   * @api private
   */


  exports = AcceptorStateMachine;
  return exports;
}