import { dew as _escapeElementDew } from "./escape-element";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var escapeElement = _escapeElementDew().escapeElement;
  /**
   * Represents an XML text value.
   * @api private
   */


  function XmlText(value) {
    (this || _global).value = value;
  }

  XmlText.prototype.toString = function () {
    return escapeElement("" + (this || _global).value);
  };
  /**
   * @api private
   */


  exports = {
    XmlText: XmlText
  };
  return exports;
}