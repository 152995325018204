import * as _core from "../core";
import { dew as _utilDew } from "../util";
import { dew as _restDew } from "./rest";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var AWS = _core.__dew ? _core.__dew() : _core.default;

  var util = _utilDew();

  var Rest = _restDew();

  function populateBody(req) {
    var input = req.service.api.operations[req.operation].input;
    var builder = new AWS.XML.Builder();
    var params = req.params;
    var payload = input.payload;

    if (payload) {
      var payloadMember = input.members[payload];
      params = params[payload];
      if (params === undefined) return;

      if (payloadMember.type === "structure") {
        var rootElement = payloadMember.name;
        req.httpRequest.body = builder.toXML(params, payloadMember, rootElement, true);
      } else {
        // non-xml payload
        req.httpRequest.body = params;
      }
    } else {
      req.httpRequest.body = builder.toXML(params, input, input.name || input.shape || util.string.upperFirst(req.operation) + "Request");
    }
  }

  function buildRequest(req) {
    Rest.buildRequest(req); // never send body payload on GET/HEAD

    if (["GET", "HEAD"].indexOf(req.httpRequest.method) < 0) {
      populateBody(req);
    }
  }

  function extractError(resp) {
    Rest.extractError(resp);
    var data;

    try {
      data = new AWS.XML.Parser().parse(resp.httpResponse.body.toString());
    } catch (e) {
      data = {
        Code: resp.httpResponse.statusCode,
        Message: resp.httpResponse.statusMessage
      };
    }

    if (data.Errors) data = data.Errors;
    if (data.Error) data = data.Error;

    if (data.Code) {
      resp.error = util.error(new Error(), {
        code: data.Code,
        message: data.Message
      });
    } else {
      resp.error = util.error(new Error(), {
        code: resp.httpResponse.statusCode,
        message: null
      });
    }
  }

  function extractData(resp) {
    Rest.extractData(resp);
    var parser;
    var req = resp.request;
    var body = resp.httpResponse.body;
    var operation = req.service.api.operations[req.operation];
    var output = operation.output;
    var hasEventOutput = operation.hasEventOutput;
    var payload = output.payload;

    if (payload) {
      var payloadMember = output.members[payload];

      if (payloadMember.isEventStream) {
        parser = new AWS.XML.Parser();
        resp.data[payload] = util.createEventStream(AWS.HttpClient.streamsApiVersion === 2 ? resp.httpResponse.stream : resp.httpResponse.body, parser, payloadMember);
      } else if (payloadMember.type === "structure") {
        parser = new AWS.XML.Parser();
        resp.data[payload] = parser.parse(body.toString(), payloadMember);
      } else if (payloadMember.type === "binary" || payloadMember.isStreaming) {
        resp.data[payload] = body;
      } else {
        resp.data[payload] = payloadMember.toType(body);
      }
    } else if (body.length > 0) {
      parser = new AWS.XML.Parser();
      var data = parser.parse(body.toString(), output);
      util.update(resp.data, data);
    }
  }
  /**
   * @api private
   */


  exports = {
    buildRequest: buildRequest,
    extractError: extractError,
    extractData: extractData
  };
  return exports;
}