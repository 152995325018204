import * as _core from "../core";
import { dew as _utilDew } from "../util";
import { dew as _query_param_serializerDew } from "../query/query_param_serializer";
import { dew as _shapeDew } from "../model/shape";
import { dew as _helpersDew } from "./helpers";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var AWS = _core.__dew ? _core.__dew() : _core.default;

  var util = _utilDew();

  var QueryParamSerializer = _query_param_serializerDew();

  var Shape = _shapeDew();

  var populateHostPrefix = _helpersDew().populateHostPrefix;

  function buildRequest(req) {
    var operation = req.service.api.operations[req.operation];
    var httpRequest = req.httpRequest;
    httpRequest.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=utf-8";
    httpRequest.params = {
      Version: req.service.api.apiVersion,
      Action: operation.name
    }; // convert the request parameters into a list of query params,
    // e.g. Deeply.NestedParam.0.Name=value

    var builder = new QueryParamSerializer();
    builder.serialize(req.params, operation.input, function (name, value) {
      httpRequest.params[name] = value;
    });
    httpRequest.body = util.queryParamsToString(httpRequest.params);
    populateHostPrefix(req);
  }

  function extractError(resp) {
    var data,
        body = resp.httpResponse.body.toString();

    if (body.match("<UnknownOperationException")) {
      data = {
        Code: "UnknownOperation",
        Message: "Unknown operation " + resp.request.operation
      };
    } else {
      try {
        data = new AWS.XML.Parser().parse(body);
      } catch (e) {
        data = {
          Code: resp.httpResponse.statusCode,
          Message: resp.httpResponse.statusMessage
        };
      }
    }

    if (data.requestId && !resp.requestId) resp.requestId = data.requestId;
    if (data.Errors) data = data.Errors;
    if (data.Error) data = data.Error;

    if (data.Code) {
      resp.error = util.error(new Error(), {
        code: data.Code,
        message: data.Message
      });
    } else {
      resp.error = util.error(new Error(), {
        code: resp.httpResponse.statusCode,
        message: null
      });
    }
  }

  function extractData(resp) {
    var req = resp.request;
    var operation = req.service.api.operations[req.operation];
    var shape = operation.output || {};
    var origRules = shape;

    if (origRules.resultWrapper) {
      var tmp = Shape.create({
        type: "structure"
      });
      tmp.members[origRules.resultWrapper] = shape;
      tmp.memberNames = [origRules.resultWrapper];
      util.property(shape, "name", shape.resultWrapper);
      shape = tmp;
    }

    var parser = new AWS.XML.Parser(); // TODO: Refactor XML Parser to parse RequestId from response.

    if (shape && shape.members && !shape.members._XAMZRequestId) {
      var requestIdShape = Shape.create({
        type: "string"
      }, {
        api: {
          protocol: "query"
        }
      }, "requestId");
      shape.members._XAMZRequestId = requestIdShape;
    }

    var data = parser.parse(resp.httpResponse.body.toString(), shape);
    resp.requestId = data._XAMZRequestId || data.requestId;
    if (data._XAMZRequestId) delete data._XAMZRequestId;

    if (origRules.resultWrapper) {
      if (data[origRules.resultWrapper]) {
        util.update(data, data[origRules.resultWrapper]);
        delete data[origRules.resultWrapper];
      }
    }

    resp.data = data;
  }
  /**
   * @api private
   */


  exports = {
    buildRequest: buildRequest,
    extractError: extractError,
    extractData: extractData
  };
  return exports;
}