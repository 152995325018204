import * as _core from "../core";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var AWS = _core.__dew ? _core.__dew() : _core.default;
  var inherit = AWS.util.inherit;
  /**
   * @api private
   */

  AWS.Signers.V2 = inherit(AWS.Signers.RequestSigner, {
    addAuthorization: function addAuthorization(credentials, date) {
      if (!date) date = AWS.util.date.getDate();
      var r = (this || _global).request;
      r.params.Timestamp = AWS.util.date.iso8601(date);
      r.params.SignatureVersion = "2";
      r.params.SignatureMethod = "HmacSHA256";
      r.params.AWSAccessKeyId = credentials.accessKeyId;

      if (credentials.sessionToken) {
        r.params.SecurityToken = credentials.sessionToken;
      }

      delete r.params.Signature; // delete old Signature for re-signing

      r.params.Signature = this.signature(credentials);
      r.body = AWS.util.queryParamsToString(r.params);
      r.headers["Content-Length"] = r.body.length;
    },
    signature: function signature(credentials) {
      return AWS.util.crypto.hmac(credentials.secretAccessKey, this.stringToSign(), "base64");
    },
    stringToSign: function stringToSign() {
      var parts = [];
      parts.push((this || _global).request.method);
      parts.push((this || _global).request.endpoint.host.toLowerCase());
      parts.push((this || _global).request.pathname());
      parts.push(AWS.util.queryParamsToString((this || _global).request.params));
      return parts.join("\n");
    }
  });
  /**
   * @api private
   */

  exports = AWS.Signers.V2;
  return exports;
}