import * as _core from "../core";
import { dew as _vDew } from "./v2";
import { dew as _v2Dew } from "./v3";
import { dew as _v3httpsDew } from "./v3https";
import { dew as _v3Dew } from "./v4";
import { dew as _sDew } from "./s3";
import { dew as _presignDew } from "./presign";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var AWS = _core.__dew ? _core.__dew() : _core.default;
  var inherit = AWS.util.inherit;
  /**
   * @api private
   */

  AWS.Signers.RequestSigner = inherit({
    constructor: function RequestSigner(request) {
      (this || _global).request = request;
    },
    setServiceClientId: function setServiceClientId(id) {
      (this || _global).serviceClientId = id;
    },
    getServiceClientId: function getServiceClientId() {
      return (this || _global).serviceClientId;
    }
  });

  AWS.Signers.RequestSigner.getVersion = function getVersion(version) {
    switch (version) {
      case "v2":
        return AWS.Signers.V2;

      case "v3":
        return AWS.Signers.V3;

      case "s3v4":
        return AWS.Signers.V4;

      case "v4":
        return AWS.Signers.V4;

      case "s3":
        return AWS.Signers.S3;

      case "v3https":
        return AWS.Signers.V3Https;
    }

    throw new Error("Unknown signing version " + version);
  };

  _vDew();

  _v2Dew();

  _v3httpsDew();

  _v3Dew();

  _sDew();

  _presignDew();

  return exports;
}