import { dew as _LRUDew } from "./utils/LRU";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var LRU_1 = _LRUDew();

  var CACHE_SIZE = 1000;
  /**
   * Inspired node-lru-cache[https://github.com/isaacs/node-lru-cache]
   */

  var EndpointCache =
  /** @class */
  function () {
    function EndpointCache(maxSize) {
      if (maxSize === void 0) {
        maxSize = CACHE_SIZE;
      }

      this.maxSize = maxSize;
      this.cache = new LRU_1.LRUCache(maxSize);
    }

    ;
    Object.defineProperty(EndpointCache.prototype, "size", {
      get: function () {
        return this.cache.length;
      },
      enumerable: true,
      configurable: true
    });

    EndpointCache.prototype.put = function (key, value) {
      var keyString = typeof key !== "string" ? EndpointCache.getKeyString(key) : key;
      var endpointRecord = this.populateValue(value);
      this.cache.put(keyString, endpointRecord);
    };

    EndpointCache.prototype.get = function (key) {
      var keyString = typeof key !== "string" ? EndpointCache.getKeyString(key) : key;
      var now = Date.now();
      var records = this.cache.get(keyString);

      if (records) {
        for (var i = 0; i < records.length; i++) {
          var record = records[i];

          if (record.Expire < now) {
            this.cache.remove(keyString);
            return undefined;
          }
        }
      }

      return records;
    };

    EndpointCache.getKeyString = function (key) {
      var identifiers = [];
      var identifierNames = Object.keys(key).sort();

      for (var i = 0; i < identifierNames.length; i++) {
        var identifierName = identifierNames[i];
        if (key[identifierName] === undefined) continue;
        identifiers.push(key[identifierName]);
      }

      return identifiers.join(" ");
    };

    EndpointCache.prototype.populateValue = function (endpoints) {
      var now = Date.now();
      return endpoints.map(function (endpoint) {
        return {
          Address: endpoint.Address || "",
          Expire: now + (endpoint.CachePeriodInMinutes || 1) * 60 * 1000
        };
      });
    };

    EndpointCache.prototype.empty = function () {
      this.cache.empty();
    };

    EndpointCache.prototype.remove = function (key) {
      var keyString = typeof key !== "string" ? EndpointCache.getKeyString(key) : key;
      this.cache.remove(keyString);
    };

    return EndpointCache;
  }();

  exports.EndpointCache = EndpointCache;
  return exports;
}