var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  /**
   * Escapes characters that can not be in an XML attribute.
   */
  function escapeAttribute(value) {
    return value.replace(/&/g, "&amp;").replace(/'/g, "&apos;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");
  }
  /**
   * @api private
   */


  exports = {
    escapeAttribute: escapeAttribute
  };
  return exports;
}